/** * Subscription screen styles. * */
.subscription-section {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
}

.subscription-section .subscription-cover {
  align-items: center;
  background-size: cover;
  display: flex;
  flex: 1 auto;
  height: 100vh;
  justify-content: center;
}

.subscription-section .subscription-cover img {
  max-height: 100px;
}
.subscription-section .subscription-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 500px;
  min-width: 400px;
  overflow: auto;
  padding: 2rem;
  text-align: center;
  width: 30%;
}

.subscription-section .subscription-container .logo {
  display: block;
  margin: 0 auto 1rem;
  position: absolute;
  right: 0;
  top: 10px;
  transform: scale(1.5);
  width: 70px;
}
.subscription-section .subscription-container .lp-logo {
  margin-top: 3rem;
  max-width: 220px;
  width: 60%;
}
.subscription-section .subscription-container .title {
  color: rgba(0, 0, 0, 0.75);
  margin-top: 2rem;
}
.subscription-section .subscription-container .form-buttons .field.is-grouped {
  justify-content: center;
}
.subscription-section .subscription-container .help {
  text-align: left;
}

button {
  background-color: #3273dc;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  border-color: transparent;
  margin: 10px 4px;
  cursor: pointer;
}

.unsubscribe-button {
  background: transparent;
  color: blue;
}

.unsubscribe-button:hover {
  background: rgba(207, 222, 255, 0.75);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
